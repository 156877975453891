<template>
  <div class="sale-admin">
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="8"
        sm="12"
      >
        <b-card>
          <b-row class="mb-50">
            <b-col v-if="orderItems">
              <h2>Документ продажи № {{ orderItems.id }}</h2>
            </b-col>
            <b-col
              v-if="orderItems && orderItems.basketTicket && orderItems.basketTicket.id"
              class="text-right"
            >
              <add-ticket-manual-button
                class="mr-1"
                :basket-ticket-id="orderItems.basketTicket.id"
              />
              <b-button
                variant="primary"
                @click="doCopy(orderItems.basketTicket)"
              >
                <feather-icon
                  icon="ClipboardIcon"
                />
                <span class="ml-25 align-middle">QR-чека (стр)</span>
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row class="d-flex flex-row">
            <b-col
              class="d-flex flex-wrap flex-column"
            >
              <h4>Заказ оформил:</h4>
              <b-media
                v-if="orderItems.createdUser"
                vertical-align="center"
              >
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="orderItems.createdUser.file ? orderItems.createdUser.file.path : null"
                    :text="avatarText(`${orderItems.createdUser.firstName} ${orderItems.createdUser.lastName || ''}`)"
                    :variant="`light-${generateRandomVariant()}`"
                    :to="{ name: 'apps-users-view', params: { id: orderItems.createdUser.id } }"
                  />
                </template>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  :to="{ name: 'apps-users-view', params: { id: orderItems.createdUser.id } }"
                >
                  {{ orderItems.createdUser.firstName }} {{ orderItems.createdUser.lastName || '' }}
                </b-link>
                <small class="text-muted">{{ orderItems.createdUser.phone }}</small>
              </b-media>
            </b-col>
            <b-col>
              <h4>Тип заказа:</h4>
              <div>
                <feather-icon
                  :icon="orderItems.team ? 'UsersIcon' : 'UserIcon'"
                  size="18"
                  class="mr-50"
                />
                <span
                  v-if="orderItems.team"
                  class="align-text-top"
                > Командный</span>
                <span
                  v-else
                  class="align-text-top"
                >персональный</span>
              </div>
            </b-col>
            <b-col>
              <h4>Информация:</h4>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="AwardIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ orderItems.bonusTotal }} бонусов
                    </h5>
                    <small>Всего в продаже</small>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ orderItems.bonusApproved }} бонусов
                    </h5>
                    <small>Одобрено в заказе</small>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card v-if="orderItems && orderItems.basketTicket && orderItems.basketTicket.company">
          <div class="mb-1 mt-1">
            <b-row>
              <b-col class="d-flex flex-column col-12 col-xl-3 col-lg-3 col-md-8 col-sm-12">
                <h3 class="mb-2">
                  кассовый чек
                </h3>
                <span class="mb-1"><b>{{ orderItems.basketTicket.company.name }}</b></span>
                <span class="mb-1">ИНН <b>{{ orderItems.basketTicket.company.taxNumber }}</b></span>
                <span class="mb-1">адрес  <b>{{ orderItems.basketTicket.company.address }}</b></span>
                <span class="mb-1">сумма <b>{{ orderItems.basketTicket.totalSum }} р.</b></span>
                <span class="mb-1">номер чека  <b>{{ orderItems.basketTicket.fiscalDocumentNumber }}</b></span>

              </b-col>

              <b-col>
                <b-table
                  title="Состав заказа"
                  show-empty
                  empty-text="Товары не найдены"
                  :items="orderItems.basketTicket.items || []"
                  :fields="TicketsColumns"
                  responsive
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-30">
            <h5 class="mb-0">
              Дата оформления
            </h5>
            <small class="text-muted w-100">{{ formatDate(orderItems.createdAt) }}</small>
          </b-card-header>

          <b-card-body>
            <ul class="list-unstyled my-1">
              <li v-if="orderItems && orderItems.status">
                <span class="align-middle">
                  <b-badge :variant="`light-${ resolveUserMotivationStatusVarian(orderItems.status.name) }`">
                    {{ resolveUserMotivationStatus(orderItems.status.title) }}
                  </b-badge>
                </span>
              </li>
            </ul>

            <edit-status-sidebar
              v-if="orderItems && orderItems.status"
              :status="String(orderItems.statusId)"
              :items="orderStatusList"
              :write-message-status="['3', '4']"
              :required-message-status="['4']"
              title="Изменить статус"
              block
              :disabled="(orderItems.statusId === 4 || orderItems.statusId === 5) && !ability.can('manage', 'all')"
              @update="(event) => updateStatus(orderItems.id, event.status, event.message, refetchData)"
            />
          </b-card-body>
        </b-card>
        <b-card no-body>
          <div>
            <h4 class="p-1">Коментарии</h4>
            <comments
              v-if="orderItems && orderItems.id"
              :order-id="orderItems.id"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-1">
            <b-col>
              <h3>Список товаров</h3>
            </b-col>
          </b-row>

          <b-table
            title="Состав заказа"
            show-empty
            empty-text="Товары не найдены"
            :items="orderItems.items"
            :fields="Columns"
          >
            <template #cell(id)="data">
              <v-select
                :value="data.item.ticketItemsId"
                class="w-100"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="orderItems.basketTicket && orderItems.basketTicket.items ? orderItems.basketTicket.items : []"
                label="name"
                placeholder="Выберите позицию"
                :reduce="x=> x.id"
                @input="addBasketItemTicketElement(data.item.id, $event, refetchData)"
              />
            </template>
          </b-table>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BBadge, BLink, BAvatar, BTable, BMedia, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { formatDate } from '@/libs/helper'
import { avatarText } from '@core/utils/filter'
import {
  getOrder, addBasketItemTicketElement, getOrderStatusList, updateStatus,
} from '@/application/orderService'

import {
  generateRandomVariant, resolveUserMotivationStatus, resolveUserMotivationStatusVarian, clearRReceiveDate, clearSumm,
} from '@/utils/UserFilter'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import EditStatusSidebar from '@/components/EditStatusSidebar.vue'
import AddTicketManualButton from '@/components/basket/AddTicketManualButton.vue'
import Comments from './components/Comments.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BTable,
    BButton,

    vSelect,

    EditStatusSidebar,

    Comments,
    AddTicketManualButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    doCopy(basketTicket) {
      this.$copyText(`t=${clearRReceiveDate(basketTicket.receiveDate)}&s=${clearSumm(basketTicket.totalSum)}&fn=${basketTicket.fiscalDriveNumber}&i=${basketTicket.fiscalDocumentNumber}&fp=${basketTicket.fiscalSign}&n=${basketTicket.operationType}`).then(() => this.$store.commit('app/SET_MESSAGE', 'Скопировано!'), () => this.$store.commit('app/SET_ERROR', 'Ошибка при копировании'))
    },
  },
  setup(prop, { emit }) {
    const orderStatusList = ref([])

    const {
      orderItems,
      Columns,
      TicketsColumns,
      refetchData,
    } = getOrder(emit)

    refetchData()

    getOrderStatusList().then(res => {
      orderStatusList.value = res
    })

    return {
      orderItems,
      Columns,
      TicketsColumns,
      refetchData,

      formatDate,
      avatarText,
      ability,

      generateRandomVariant,
      resolveUserMotivationStatus,
      resolveUserMotivationStatusVarian,

      addBasketItemTicketElement,
      getOrderStatusList,
      orderStatusList,
      updateStatus,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.sale-admin .v-select {
  min-width: 350px;
}
.sale-admin .v-select.vs--single .vs__selected {
  width: 90%;
}

.item-info {
  margin-bottom: 10px;
}

</style>
