import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/company/', { params })).data

    if (error) throw error

    return result
  },
}
