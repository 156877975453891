var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"text-left"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.isAddTicketManualSidebarActive = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-25 align-middle"},[_vm._v("Добавить чек")])],1),_c('b-sidebar',{attrs:{"id":"add-ticket-manual-sidebar","visible":_vm.isAddTicketManualSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.onHidden,"change":function (val) { return _vm.isAddTicketManualSidebarActive = val; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Выберите продавца (компанию) ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"custom-control-primary",staticStyle:{"display":"inline-block"},attrs:{"switch":""},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}),(_vm.checked)?_c('span',[_vm._v("Чек в JSON")]):_c('span',[_vm._v("По продавцу (фиктивный)")])],1),(!_vm.checked)?_c('validation-provider',{attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"searchCompany","label":"Поиск продавца:","invalid-feedback":validationContext.errors[0],"state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"id":"searchCompany","value":_vm.company ? _vm.company.id : null,"filterable":false,"options":_vm.companyOptions,"reduce":function (val) { return val.id; },"placeholder":"Выберите продавца"},on:{"search":_vm.searchElement,"input":_vm.onInput,"open":_vm.onOpen,"close":_vm.onClose},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [(_vm.isLoading)?_c('span',_vm._b({},'span',attributes,false)):_vm._e()]}},{key:"spinner",fn:function(ref){
var loading = ref.loading;
return [_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(loading || _vm.isLoading),expression:"loading || isLoading"}],attrs:{"small":"","variant":"primary","label":"Spinning"}})]}},{key:"list-footer",fn:function(){return [(_vm.hasNextPage)?_c('li',{ref:"refLoad"},[_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"text-",attrs:{"variant":"primary","label":"Spinning"}})],1)]):_vm._e()]},proxy:true}],null,true)},[_c('template',{slot:"no-options"},[_vm._v(" Введите для поиска продавца ")])],2)],1)]}}],null,true)}):_vm._e(),(_vm.checked)?_c('validation-provider',{attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"dataInJson","label":"Введите данный чека в JSON:","invalid-feedback":validationContext.errors[0],"state":_vm.getValidationState(validationContext)}},[_c('b-form-textarea',{attrs:{"id":"dataInJson","placeholder":"Введите данный чека в JSON","rows":"8","state":_vm.getValidationState(validationContext)},model:{value:(_vm.dataInJson),callback:function ($$v) {_vm.dataInJson=$$v},expression:"dataInJson"}})],1)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Применить ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Отмена ")])],1)])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }