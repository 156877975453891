<template>
  <div class="p-50">
    <b-overlay rounded="pill" :show="isLoading">
      <div class="comments-chats w-100">
        <comment-items v-if="(messages || []).length > 0" :items="messages" />
        <span v-else> Комментарии отсутствуют </span>
      </div>
    </b-overlay>
    <b-form @submit.prevent="sendMessage">
      <b-form-textarea
        v-model="chatInputMessage"
        rows="1"
        max-rows="2"
        placeholder="Введите сообщение"
      />
      <b-button
        class="col-12 mb-1 mt-1"
        variant="primary"
        type="submit"
        :disabled="isLoading"
      >
        Отправить
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormTextarea, BButton, BOverlay,
} from 'bootstrap-vue'
import { editComments } from '@/application/orderService'
import { ref, toRefs } from '@vue/composition-api'
import CommentItems from './CommentItems.vue'

export default {
  components: {
    BForm,
    BFormTextarea,
    BButton,
    BOverlay,

    CommentItems,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    this.$parent.$on('fetchMessage', () => this.getChatOrderMessage(this.orderId).then())
    return {}
  },
  setup(props) {
    const isLoading = ref(false)
    const { orderId } = toRefs(props)

    const {
      chatInputMessage,
      messages,
      getChatOrderMessage,
      createOrderMessage,
    } = editComments()

    const refetch = () => getChatOrderMessage(orderId.value).then()
    refetch()

    const sendMessage = () => {
      try {
        isLoading.value = true
        createOrderMessage(orderId.value).then(() => {
          refetch()
        })
        isLoading.value = false
      } catch (error) {
        isLoading.value = true
      }
    }

    return {
      isLoading,
      chatInputMessage,
      messages,
      getChatOrderMessage,
      createOrderMessage,
      sendMessage,
    }
  },
}
</script>

<style lang="scss" scoped>
.comments-chats {
  position: relative;
  height: 250px;
  overflow-x: auto;
  overflow-x: hidden !important;
}
</style>
