<template>
  <span class="text-left">
    <b-button
      variant="outline-primary"
      @click="isAddTicketManualSidebarActive = true"
    >
      <feather-icon
        icon="PlusIcon"
      />
      <span class="ml-25 align-middle">Добавить чек</span>
    </b-button>
    <b-sidebar
      id="add-ticket-manual-sidebar"
      :visible="isAddTicketManualSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="onHidden"
      @change="(val) => isAddTicketManualSidebarActive = val"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Выберите продавца (компанию)
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="checked"
                class="custom-control-primary"
                style="display: inline-block;"
                switch
              />
              <span v-if="checked">Чек в JSON</span>
              <span v-else>По продавцу (фиктивный)</span>
            </b-form-group>

            <validation-provider
              v-if="!checked"
              #default="validationContext"
              name=" "
              rules="required"
            >
              <b-form-group
                label-for="searchCompany"
                label="Поиск продавца:"
                :invalid-feedback="validationContext.errors[0]"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  id="searchCompany"
                  :value="company ? company.id : null"
                  :filterable="false"
                  :options="companyOptions"
                  :reduce="val => val.id"
                  placeholder="Выберите продавца"
                  @search="searchElement"
                  @input="onInput"
                  @open="onOpen"
                  @close="onClose"
                >
                  <template slot="no-options">
                    Введите для поиска продавца
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span
                      v-if="isLoading"
                      v-bind="attributes"
                    />
                  </template>
                  <template v-slot:spinner="{ loading }">
                    <b-spinner
                      v-show="loading || isLoading"
                      small
                      variant="primary"
                      label="Spinning"
                    />
                  </template>
                  <template #list-footer>
                    <li
                      v-if="hasNextPage"
                      ref="refLoad"
                    >
                      <div class="text-center">
                        <b-spinner
                          variant="primary"
                          label="Spinning"
                          class="text-"
                        />
                      </div>
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="checked"
              #default="validationContext"
              name=" "
              rules="required"
            >
              <b-form-group
                label-for="dataInJson"
                label="Введите данный чека в JSON:"
                :invalid-feedback="validationContext.errors[0]"
                :state="getValidationState(validationContext)"
              >
                <b-form-textarea
                  id="dataInJson"
                  v-model="dataInJson"
                  placeholder="Введите данный чека в JSON"
                  rows="8"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
            </validation-provider>

            <div class="py-1">
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Применить
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Отмена
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </span></template>

<script>
import {
  BButton, BSidebar, BForm, BFormGroup, BSpinner, BFormCheckbox, BFormTextarea, // BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  ref, computed, nextTick, onMounted, toRefs,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ability from '@/libs/acl/ability'
import companyRepository from '@/repository/companyRepository'
import { addSellerFromTicket, ticketVerificationFromJSON } from '@/application/basketService'
import store from '@/store'

localize('ru', ru)

export default {
  name: 'AddTicketManualButton',
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    basketTicketId: {
      default: null,
    },
  },
  methods: {
    // перенести в сетап неполучилось из за this.$refs.refLoad
    async onOpen() {
      this.currentPage = 1
      const { items, count } = await this.fetchItems()
      this.companyOptions = items
      this.totalItems = count
      await this.$nextTick()
      if (this.hasNextPage) {
        this.observer.observe(this.$refs.refLoad)
      }
    },
  },
  setup(props, { emit }) {
    const checked = ref(true)
    const dataInJson = ref(null)
    const isAddTicketManualSidebarActive = ref(false)
    const isLoading = ref(false)
    const company = ref(null)
    const companyOptions = ref([])
    const currentPage = ref(1)
    const searchQuery = ref(null)
    const totalItems = ref(0)

    const refLoad = ref(null)
    const observer = ref(null)

    const { basketTicketId } = toRefs(props)

    const {
      refFormObserver,
      getValidationState,
      resetObserver,
    } = formValidation()

    const fetchItems = async () => {
      try {
        isLoading.value = true
        const { items, count } = await companyRepository.elementList({
          page: currentPage.value,
          query: searchQuery.value ?? null,
          limit: 10,
          sidx: 'name',
          sord: 'asc',
        })
        isLoading.value = false
        return { items: items.map(x => ({ id: x.id, label: x.name })), count }
      } catch (error) {
        isLoading.value = false
        store.commit('app/SET_ERROR', error)
        return { items: [], count: 0 }
      }
    }

    const hasNextPage = computed(() => companyOptions.value.length < totalItems.value)

    const searchElement = async (query, loading) => {
      if (query) {
        loading(true)
        searchQuery.value = query
        currentPage.value = 1
        const { items, count } = await fetchItems()
        companyOptions.value = items
        totalItems.value = count
        loading(false)
      }
    }

    const onSubmit = async () => {
      try {
        if (checked.value) {
          await ticketVerificationFromJSON(basketTicketId.value, dataInJson.value)
        } else {
          await addSellerFromTicket(basketTicketId.value, company.value.id)
        }

        emit('refresh')
        isAddTicketManualSidebarActive.value = false
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    const onInput = companyId => {
      company.value = companyOptions.value.find(x => String(x.id) === String(companyId))
      searchQuery.value = null
      currentPage.value = 1
      totalItems.value = 0
    }

    const onHidden = () => {
      company.value = null
      companyOptions.value = []
      searchQuery.value = null
      currentPage.value = 1
      totalItems.value = 0
      dataInJson.value = null
      checked.value = true
      resetObserver()
    }

    async function infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        currentPage.value += 1
        const { items, count } = await fetchItems()
        companyOptions.value.push(...items)
        totalItems.value = count
        await nextTick()
        ul.scrollTop = scrollTop
      }
    }

    async function onClose() {
      if (company.value) {
        companyOptions.value = [...new Set([...companyOptions.value, company.value])]
      }
      searchQuery.value = null
      currentPage.value = 1
      observer.value.disconnect()
      await nextTick()
    }

    onMounted(() => {
      observer.value = new IntersectionObserver(infiniteScroll)
    })

    return {
      checked,
      dataInJson,
      isAddTicketManualSidebarActive,
      ability,
      isLoading,
      company,
      companyOptions,

      searchElement,
      onInput,

      onSubmit,
      onHidden,
      fetchItems,

      required,
      refFormObserver,
      getValidationState,

      currentPage,
      searchQuery,
      totalItems,
      hasNextPage,

      refLoad,
      observer,
      onClose,
    }
  },
}
</script>
