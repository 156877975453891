<template>
  <div>
    <b-card v-if="items && (items || []).length > 0">
      <b-row
        v-for="item in items"
        :key="item.id"
        class="flex-column"
      >
        <div>
          <b-col
            class="d-flex flex-wrap flex-column"
          >
            <b-media
              vertical-align="center"
              :class="{'d-flex flex-row-reverse text-right':item.userRole === 'moderator'}"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="item.user.file ? item.user.file.path : null"
                  :text="avatarText(`${item.user.lastName} ${item.user.firstName || ''}`)"
                  :variant="`light-${generateRandomVariant()}`"
                />
              </template>
              <div
                class="font-weight-bold d-block text-nowrap pr-1 text-primary flex-row-reverse"
              >
                {{ item.user.lastName }} {{ item.user.firstName }}
              </div>
              <small class="text-muted pr-1">{{ item.userRole }}</small>
            </b-media>
          </b-col>
          <b-col class="d-flex flex-row align-items-center justify-content-between flex-wrap mt-1">
            <div :class="{'admin-message':item.userRole === 'moderator', 'client-message':item.userRole !== 'moderator'}">
              <div> {{ item.message }} </div>
              <div class="created-at">
                {{ formatDate(item.createdAt) }}
              </div>
            </div>
          </b-col>

        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BAvatar, BCard, BMedia,
} from 'bootstrap-vue'
import { formatDate } from '@/libs/helper'
import { avatarText } from '@core/utils/filter'
import { generateRandomVariant } from '@/utils/UserFilter'
import { getUserId } from '@/application/userService'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BCard,
    BMedia,
  },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    const userId = getUserId()

    return {
      formatDate, avatarText, generateRandomVariant, userId,
    }
  },
}
</script>

<style lang="scss" scoped>

.admin-message {
  position: relative;
padding:20px;

display: inline-flex;
    zoom: 1;
    width: 100%;
    margin-right: -100%;
    vertical-align: bottom;
    flex-direction: column;
  word-break: break-all;
  white-space:pre-wrap;
  padding: 10px;
  background: #ecf1f8;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.client-message {
position: relative;
padding:20px;
display: inline-flex;
    zoom: 1;
    width: 100%;
    margin-right: -100%;
    vertical-align: bottom;
    flex-direction: column;
  word-break: break-all;
  white-space:pre-wrap;
  padding: 10px;
  background: #f7e9e9;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.created-at {
    text-align: right;
    font-weight: bold;
    font-size: 9px;
    margin-bottom: -6px;
  }
  .user-name {
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
  }

</style>
