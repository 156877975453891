<template>
  <div>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      :variant="variant"
      :block="block"
      :disabled="disabled"
      @click="isEditStatusSidebarActive = true"
    >
      <feather-icon
        v-if="icon"
        :icon="icon"
      />
      {{ title }}
    </b-button>

    <b-sidebar
      id="edit-status-sidebar"
      :visible="isEditStatusSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="shownSidebar"
      @change="(val) => isEditStatusSidebarActive = val"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ title }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <validation-provider
              #default="validationContext"
              name=" "
              rules="required|statusValid"
            >
              <b-form-group
                label="Модуль"
                label-for="status"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="elementData.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="items"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="(requiredMessageStatus || []).length > 0 && requiredMessageStatus.includes(elementData.status) || (writeMessageStatus || []).length > 0 && writeMessageStatus.includes(elementData.status)"
              #default="validationContext"
              name="  "
              :rules="`${(requiredMessageStatus || []).length > 0 && requiredMessageStatus.includes(elementData.status) ? 'required' : ''}`"
            >
              <b-form-group
                label="Комментарий"
                label-for="message"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="message"
                  v-model="elementData.message"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Применить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Отмена
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import vSelect from 'vue-select'
import { ref, toRefs } from '@vue/composition-api'
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import { required } from '@validations'
import { required, statusValid } from '@core/utils/validations/validations'
// import moderationService from '@/application/moderationService'

localize('ru', ru)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    writeMessageStatus: {
      type: Array,
      default: () => ([]),
    },
    requiredMessageStatus: {
      type: Array,
      default: () => ([]),
    },
    icon: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isEditStatusSidebarActive = ref(false)
    // const refFormObserver = ref(null)
    const { status } = toRefs(props)
    const elementData = ref({
      status: null,
      message: '',
    })

    const shownSidebar = () => {
      elementData.value = JSON.parse(JSON.stringify({
        status: status.value,
        message: '',
      }))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const onSubmit = () => {
      const success = refFormObserver.value.validate()
      if (success) {
        emit('update', elementData.value)
        isEditStatusSidebarActive.value = false
      }
    }

    return {
      isEditStatusSidebarActive,
      refFormObserver,

      shownSidebar,
      onSubmit,

      elementData,
      required,
      statusValid,

      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
